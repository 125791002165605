import React, { CSSProperties, ReactNode, useEffect, useRef } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useLocation } from "react-router-dom"
import "./checkbox.scss"

type Props = {
  label?: string | ReactNode
  name?: string
  value?: boolean
  disabled?: boolean
  className?: string
  readonly?: boolean
  required?: boolean
  onChange?: any
  verySmall?: boolean
  small?: boolean
  medium?: boolean
  title?: boolean | undefined
  labelStyle?: CSSProperties
  labelClassName?: string
  style?: CSSProperties
  defaultValue?: boolean | undefined
  indeterminate?: boolean
}

export const Checkbox: React.FC<Props> = React.memo(
  ({
    label = "",
    name,
    readonly,
    className = "",
    disabled,
    value,
    required,
    verySmall,
    small,
    medium,
    onChange,
    title,
    labelStyle = {},
    labelClassName = "",
    style = {},
    defaultValue,
    indeterminate,
  }) => {
    const formContext = useFormContext()
    const { register, setValue, control } = formContext || {}
    const location = useLocation()
    const ref = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (typeof indeterminate === "boolean") {
        // @ts-expect-error not null
        ref.current.indeterminate = !value && indeterminate
      }
    }, [ref, indeterminate])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(name as string, event.target.checked, {
        shouldValidate: true,
        shouldDirty: true,
      })
    }

    return (
      <label
        className={`checkbox ${className} ${verySmall ? "very-small" : ""} ${
          small ? "small" : ""
        } ${medium ? "medium" : ""}`}
        title={title && typeof label === "string" ? label : undefined}
        style={style}
      >
        {name ? (
          <Controller
            name={name}
            control={control}
            rules={{ required }}
            defaultValue={defaultValue}
            render={({ field }) => (
              <input
                type="checkbox"
                {...register(name)}
                checked={field.value}
                disabled={disabled}
                readOnly={readonly}
                onChange={handleChange}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                className={`checkbox-input ${
                  location.pathname.includes("audit-catalog")
                    ? "w-6 h-6 mx-1 my-2"
                    : "w-5 h-5 m-2"
                }`}
              />
            )}
          />
        ) : (
          <input
            ref={ref}
            type="checkbox"
            checked={value}
            disabled={disabled}
            readOnly={readonly}
            onChange={onChange}
            onClick={(e) => {
              e.stopPropagation()
            }}
            className={`checkbox-input ${
              location.pathname.includes("audit-catalog")
                ? "w-6 h-6 mx-1 my-2"
                : "w-5 h-5 m-2"
            }`}
          />
        )}
        <span
          style={labelStyle}
          className={`checkbox-label ${labelClassName}`}
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          {label}
        </span>
      </label>
    )
  },
)
