import { RematchDispatch } from "@rematch/core"
import { ColumnSizingState } from "@tanstack/react-table"

import { WORKSPACE_ID } from "api/api"
import {
  getCatalogFiltersApi,
  getCatalogItemsApi,
  getCatalogColumnsSummaryFilteredApi,
  getCatalogColumnsSummaryApi,
  getCatalogItemsCoutnApi,
  deleteCatalogItemsApi,
  getPreviousVersionCatalogItemsApi,
  getPreviousVersionCatalogItemsCountApi,
  getPreviousVersionCatalogColumnsSummaryApi,
} from "api/endpoints/catalog.api"
import { SortDirection } from "common/enums/SortDirection.enum"
import { CatalogDynamicFiltersModel } from "common/models/CatalogDynamicFiltersModel"
import { CatalogRequestOptions } from "common/models/CatalogRequestOptions"
import { PageModel } from "common/models/PageModel"
import { renderObject } from "pages/catalogs/audit-catalog/catalog-columns/column-definitions"
import { columnSummaryAccessorToCamelCase } from "./store-utils"
import { CatalogColumnsSummaryModel } from "common/models/CatalogColumnsSummaryModel"
import { ItemPropertySummary } from "common/models/ItemPropertySummary"
import { updateArrayData } from "./store-utils"
import { CatalogSavedViewModel } from "common/models/CatalogSavedViewModel"
import {
  copySharedViewApi,
  createSavedViewApi,
  deleteUserSavedViewsApi,
  deleteUserSharedViewsApi,
  getAllSharedViewsApi,
  getAllUserSavedViewsApi,
  renameUserSavedViewApi,
  setViewAsDefaultApi,
  shareViewApi,
  updateUserSavedViewApi,
} from "api/endpoints/saved-views.api"
import { UpdateSavedViewsModel } from "common/models/UpdateSavedViewsModel"
import { getWorkspaceTagsApi } from "api/endpoints/workspace.api"
import { getAllApi } from "api/endpoints/alerts.api"
import { getCatalogColumnsApi } from "api/endpoints/columns.api"
import { UpdateCatalogSavedViewModel } from "common/models/UpdateCatalogSavedViewModel"
import { CatalogSharedViewsModel } from "common/models/CatalogSharedViewsModel"
import { ItemDashboardFilterModel } from "../common/models/ItemDashboardFilterModel"
import { getTotalFiltersNumber } from "utils"
import { RState } from "store"
import { CatalogItemModel } from "common/models/CatalogItemModel"

type State = Readonly<{
  products: (CatalogItemModel & { selected?: boolean })[]
  totalProductsCount: number
  selectedProducts: string[]
  filterModel: CatalogDynamicFiltersModel | null
  itemDashboardFilterModel: ItemDashboardFilterModel | null
  requestOptions: CatalogRequestOptions
  columns: any[]
  columnOrder: any[]
  columnSizing: ColumnSizingState
  previousView: CatalogSavedViewModel | null
  columnSummary: ItemPropertySummary[]
  isRedirectFromMyAudits: boolean
  catalogViews: CatalogSavedViewModel[]
  sharedViews: CatalogSharedViewsModel[]
  selectedCatalogView: CatalogSavedViewModel | null
  selectedCatalogViewToLoad: CatalogSavedViewModel | null
  showDefaultView: boolean
  isAddCustomViewVisible: boolean
  isAddingNewView: boolean
  expandedId: string
  importedSharedViewId: string
  menuId: string
  isRedirectFromPreviousVersion: boolean
}>

const model = {
  state: {
    products: [],
    totalProductsCount: 0,
    selectedProducts: [],
    filterModel: null,
    itemDashboardFilterModel: null,
    requestOptions: new CatalogRequestOptions({
      pageModel: new PageModel({
        page: 1,
        pageSize: 100,
        sortField: "currentSnapshot.createdAt",
        sortDirection: SortDirection.Descending,
      }),
    }),
    columns: [],
    columnOrder: [],
    columnSizing: {},
    previousView: null,
    columnSummary: [],
    isRedirectFromMyAudits: false,
    catalogViews: [],
    sharedViews: [],
    selectedCatalogView: null,
    selectedCatalogViewToLoad: null,
    showDefaultView: true,
    isAddCustomViewVisible: false,
    isAddingNewView: false,
    expandedId: "",
    importedSharedViewId: "",
    menuId: "",
    isRedirectFromPreviousVersion: false,
  } as State,
  reducers: {
    loaded: (state: State, payload: any): State => ({
      ...state,
      products: updateArrayData(state.products, payload.data, payload.pageModel.page),
    }),
    selectAllProducts: (state: State, payload: any): State => ({
      ...state,
      products: state.products.map((product) => ({
        ...product,
        selected: payload,
      })),
    }),
    setTotalProductsCount: (state: State, payload: number): State => ({
      ...state,
      totalProductsCount: payload,
    }),
    setSelectedProducts: (state: State, payload: any): State => ({
      ...state,
      selectedProducts: payload,
    }),

    setRedirectFromMyAudits: (state: State, payload: boolean): State => ({
      ...state,
      isRedirectFromMyAudits: payload,
    }),

    resetProducts: (state: State): State => ({
      ...state,
      products: [],
    }),
    resetColumnSummary: (state: State): State => ({
      ...state,
      columnSummary: [],
    }),
    resetAuditCatalogView: (state: State): State => ({
      ...state,
      columnOrder: [],
      selectedCatalogView: null,
      previousView: null,
    }),
    selectProduct: (state: State, payload: any): State => ({
      ...state,
      products: state.products.map((product) => ({
        ...product,
        selected: product.id === payload.id ? payload.selected : product.selected,
      })),
    }),
    setFilterModel: (state: State, payload: CatalogDynamicFiltersModel): State => ({
      ...state,
      filterModel: payload,
    }),
    setRequestOptions: (state: State, payload: CatalogRequestOptions): State => {
      return {
        ...state,
        requestOptions: payload,
      }
    },
    filterProducts: (state: State, payload: string): State => ({
      ...state,
      products: state.products.filter((product) =>
        product.currentSnapshot.data.title?.toLowerCase().includes(payload.toLowerCase()),
      ),
    }),
    setGridColumns: (state: State, payload: any): State => ({
      ...state,
      columns: payload,
    }),
    setPreviousView: (state: State, payload: any): State => ({
      ...state,
      previousView: payload,
    }),
    setColumnOrder: (state: State, payload: any): State => ({
      ...state,
      columnOrder: payload,
    }),
    setColumnSizing: (state: State, payload: ColumnSizingState): State => ({
      ...state,
      columnSizing: payload,
    }),
    setColumnSummary: (state: State, payload: any): State => {
      return { ...state, columnSummary: payload }
    },
    setCatalogViews: (state: State, payload: any): State => ({
      ...state,
      catalogViews: payload,
    }),
    setSharedViews: (state: State, payload: any): State => ({
      ...state,
      sharedViews: payload,
    }),
    setSelectedCatalogView: (state: State, payload: any): State => ({
      ...state,
      selectedCatalogView: payload,
    }),
    setSelectedCatalogViewToLoad: (state: State, payload: any): State => ({
      ...state,
      selectedCatalogViewToLoad: payload,
    }),
    setShowDefaultView: (state: State, payload: boolean): State => ({
      ...state,
      showDefaultView: payload,
    }),
    setIsAddCustomViewVisible: (state: State, payload: boolean): State => ({
      ...state,
      isAddCustomViewVisible: payload,
    }),
    setIsAddingNewView: (state: State, payload: boolean): State => ({
      ...state,
      isAddingNewView: payload,
    }),
    setExpandedId: (state: State, payload: string): State => ({
      ...state,
      expandedId: payload,
    }),
    setImportedSharedViewId: (state: State, payload: string): State => ({
      ...state,
      importedSharedViewId: payload,
    }),
    setMenuId: (state: State, payload: string): State => ({
      ...state,
      menuId: payload,
    }),
    setIsRedirectFromPreviousVersion: (state: State, payload: boolean): State => ({
      ...state,
      isRedirectFromPreviousVersion: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchAuditCatalogData(
      payload: { options: CatalogRequestOptions; instanceId: string },
      rootState: RState,
    ) {
      const { options, instanceId } = payload
      const { requestOptions, catalogViews, isRedirectFromMyAudits } =
        rootState.auditCatalog
      const { itemFilterModel, itemDashboardFilterModel } = requestOptions
      const totalFiltersCounter = getTotalFiltersNumber(
        itemFilterModel,
        itemDashboardFilterModel,
      )

      const searchValue = itemFilterModel.searchValue
      const isSearchEmpty = searchValue === "" || searchValue === undefined

      const loadColumnSummary = () => {
        if (instanceId !== "") {
          this.fetchPreviousVerisonColumnSummary({
            jobInstanceId: instanceId,
            body: options,
          })
        } else {
          if (totalFiltersCounter > 0 || !isSearchEmpty) {
            this.fetchColumnSummaryFiltered(options)
          } else {
            this.fetchColumnSummary()
          }
        }
      }

      if (!catalogViews || catalogViews.length === 0) {
        const workspaceViews = await getAllUserSavedViewsApi(WORKSPACE_ID)
        const sharedViews = await getAllSharedViewsApi(WORKSPACE_ID)
        await dispatch.auditCatalog.setSelectedCatalogView(
          workspaceViews ? workspaceViews.find((view) => view.isDefaultView) : [],
        )
        dispatch.auditCatalog.setCatalogViews(workspaceViews)
        dispatch.auditCatalog.setSharedViews(sharedViews)

        await this.getCatalogFilters()

        if (!isRedirectFromMyAudits) {
          loadColumnSummary()
        }
      } else {
        loadColumnSummary()
      }
    },
    async fetchAuditCatalogCount(payload: any) {
      const items = await getCatalogItemsCoutnApi(WORKSPACE_ID, payload)

      if (items) {
        dispatch.auditCatalog.setTotalProductsCount(
          typeof items.totalItemsCount === "string"
            ? parseInt(items.totalItemsCount)
            : items.totalItemsCount,
        )
      }
    },

    async fetchPreviousVersionAuditCatalogCount(payload: any) {
      const { jobInstanceId, body } = payload
      const items = await getPreviousVersionCatalogItemsCountApi(
        WORKSPACE_ID,
        jobInstanceId,
        body,
      )

      if (items) {
        dispatch.auditCatalog.setTotalProductsCount(
          typeof items.totalItemsCount === "string"
            ? parseInt(items.totalItemsCount)
            : items.totalItemsCount,
        )
      }
    },

    async fetchAuditCatalogItems(payload: any, rootState: RState) {
      const response = await getCatalogItemsApi(WORKSPACE_ID, payload.options)

      dispatch.auditCatalog.loaded({
        data: response?.items.map((product) => ({
          ...product,
          selected:
            product.id && rootState.auditCatalog.selectedProducts.includes(product.id),
        })),
        pageModel: payload.options.pageModel,
      })
      dispatch.auditCatalog.setIsRedirectFromPreviousVersion(false)
      return response
    },

    async fetchPreviousVersionAuditCatalogItems(payload: any, rootState: RState) {
      const { jobInstanceId, body } = payload
      const response = await getPreviousVersionCatalogItemsApi(
        WORKSPACE_ID,
        jobInstanceId,
        body,
      )
      dispatch.auditCatalog.loaded({
        data: response?.items.map((product) => ({
          ...product,
          selected:
            product.id && rootState.auditCatalog.selectedProducts.includes(product.id),
        })),
        pageModel: payload.body.pageModel,
      })
      return response
    },

    async fetchAuditCatalogColumns(payload: any) {
      const flagTypes = ["Critical Flags", "Warning Flags", "Indicator Flags"]
      const exceptedColumnNames = [
        " Critical Flags(count)",
        " Warning Flags(count)",
        " Indicator Flags(count)",
      ]

      const workspaceTags = await getWorkspaceTagsApi(WORKSPACE_ID)
      const alerts = await getAllApi(WORKSPACE_ID)
      const columns = await getCatalogColumnsApi(WORKSPACE_ID)

      if (columns && workspaceTags && alerts) {
        await dispatch.tags.setWorkspaceTags(workspaceTags)
        await dispatch.alerts.alertsLoaded(alerts)

        const renderColumns = columns.map((column) => {
          const accessor = column.accessor || ""
          if (renderObject[accessor as keyof typeof renderObject]) {
            return {
              ...column,
              render: renderObject[accessor as keyof typeof renderObject],
            }
          } else if (
            flagTypes.includes(column.groupName || "") &&
            !exceptedColumnNames.includes(column.label || "")
          ) {
            const splitId = column.columnId?.split("-")[1]
            return {
              ...column,
              render: (row: any) => (
                <span>
                  {row.triggeredAlertsIds.includes(splitId || "") ? "Failed" : "-"}
                </span>
              ),
            }
          } else if (
            column.groupName === "All System Tags" ||
            column.groupName === "All Custom Tags"
          ) {
            return {
              ...column,
              render: (row: any) => (
                <span>{row.tags.includes(column.label) ? "Yes" : "-"}</span>
              ),
            }
          } else if (column.columnId?.startsWith("custom-field-")) {
            return {
              ...column,
              render: (row: any) => {
                const customField = row.customFields?.find(
                  (field: any) => field.key === column.label,
                )
                return <span>{customField?.value ?? "-"}</span>
              },
            }
          } else {
            return column
          }
        })
        const oldColumnsSelection = renderColumns.map((column) => {
          if (payload.includes(column.label)) {
            return { ...column, isChecked: true }
          } else {
            return column
          }
        })
        await dispatch.auditCatalog.setGridColumns(oldColumnsSelection)
      }
    },
    async getCatalogFilters() {
      const filters = await getCatalogFiltersApi(WORKSPACE_ID)

      if (filters) {
        await dispatch.auditCatalog.setFilterModel(filters)
      }
    },
    async fetchColumnSummaryFiltered(filter: CatalogRequestOptions) {
      const columnSummary: CatalogColumnsSummaryModel =
        await getCatalogColumnsSummaryFilteredApi(WORKSPACE_ID, filter)

      if (columnSummary) {
        const lowerCaseAccColumnSummary = columnSummaryAccessorToCamelCase(
          columnSummary.summaries,
        )
        await dispatch.auditCatalog.setColumnSummary(lowerCaseAccColumnSummary)
      }
    },
    async fetchColumnSummary() {
      const columnSummary: CatalogColumnsSummaryModel = await getCatalogColumnsSummaryApi(
        WORKSPACE_ID,
      )
      if (columnSummary) {
        const lowerCaseAccColumnSummary = columnSummaryAccessorToCamelCase(
          columnSummary.summaries,
        )
        await dispatch.auditCatalog.setColumnSummary(lowerCaseAccColumnSummary)
      }
    },
    async fetchPreviousVerisonColumnSummary(payload: {
      jobInstanceId: string
      body: CatalogRequestOptions
    }) {
      const { jobInstanceId, body } = payload
      const columnSummary: CatalogColumnsSummaryModel =
        await getPreviousVersionCatalogColumnsSummaryApi(
          WORKSPACE_ID,
          jobInstanceId,
          body,
        )

      if (columnSummary) {
        const lowerCaseAccColumnSummary = columnSummaryAccessorToCamelCase(
          columnSummary.summaries,
        )
        await dispatch.auditCatalog.setColumnSummary(lowerCaseAccColumnSummary)
      }
    },
    async fetchAllSavedViews(setImportedSharedView = false) {
      const workspaceViews = await getAllUserSavedViewsApi(WORKSPACE_ID)

      if (workspaceViews) {
        await dispatch.auditCatalog.setCatalogViews(workspaceViews)
        if (setImportedSharedView) {
          dispatch.auditCatalog.setImportedSharedViewId(
            workspaceViews[workspaceViews.length - 1].catalogSavedViewId,
          )
        }
      }
    },
    async createCatalogView(payload: CatalogSavedViewModel) {
      const newCatalogView = await createSavedViewApi(WORKSPACE_ID, payload)

      if (newCatalogView) {
        this.fetchAllSavedViews()
      }
      return newCatalogView
    },
    async makeDefaultView(payload: string) {
      const result = await setViewAsDefaultApi(WORKSPACE_ID, payload)

      if (result) {
        this.fetchAllSavedViews()
      }
      return result
    },
    async updateCatalogView(payload: {
      catalogViewId: string
      updatedView: UpdateSavedViewsModel
    }) {
      const result = await renameUserSavedViewApi(
        WORKSPACE_ID,
        payload.catalogViewId,
        payload.updatedView,
      )

      if (result) {
        this.fetchAllSavedViews()
      }
      return result
    },
    async deleteCatalogViews(payload: string[]) {
      const result = await deleteUserSavedViewsApi(WORKSPACE_ID, payload)

      if (result) {
        this.fetchAllSavedViews()
      }
      return result
    },
    async overwriteCatalogView(payload: {
      saveViewId: string
      updatedView: UpdateCatalogSavedViewModel
    }) {
      const result = await updateUserSavedViewApi(
        payload.saveViewId,
        WORKSPACE_ID,
        payload.updatedView,
      )

      if (result) {
        this.fetchAllSavedViews()
      }
      return result
    },
    async fetchAllSharedViews() {
      const sharedViews = await getAllSharedViewsApi(WORKSPACE_ID)
      await dispatch.auditCatalog.setSharedViews(sharedViews)
    },
    async shareCatalogView(payload: { workspaces: string[]; savedViewId: string }) {
      const result = await shareViewApi(
        payload.savedViewId,
        WORKSPACE_ID,
        payload.workspaces,
      )

      if (result) {
        this.fetchAllSharedViews()
      }
      return result
    },
    async copySharedViewToYourWorkspace(payload: string) {
      const result = await copySharedViewApi(WORKSPACE_ID, payload)

      if (result) {
        this.fetchAllSavedViews(true)
      }
      return result
    },
    async deleteSharedViews(payload: string[]) {
      const result = await deleteUserSharedViewsApi(WORKSPACE_ID, payload)

      if (result) {
        this.fetchAllSharedViews()
      }
      return result
    },
    async deleteCatalogItems(payload: any, rootState: RState) {
      const result = await deleteCatalogItemsApi(WORKSPACE_ID, payload.selectedProducts)

      if (result) {
        dispatch.auditCatalog.setSelectedProducts([])
        this.fetchAuditCatalogItems(payload, rootState)
        this.fetchAuditCatalogCount(rootState)
      }
      return result
    },
  }),
}

export default model
